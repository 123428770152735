<template>
  <div class="modular_all_wrap">
    <div class="modular_wrap" style="width: 97%;">
      <div class="modular_wrap_center">
        <div class="modular_subtitle">集团·公司</div>
        <div v-for="(item, index) in pamarsUrl" :key="index" class="modular_line" @click="Jump(item.dictKey)">
          <img :src="item.pic" alt="" class="modular_line_pic">
        </div>
      </div>
      <div class="modular_wrap_center" style="padding: 0;">
        <el-carousel :interval="5000" style="height: 100%; width: 100%" indicator-position="none" v-if="AdDataValue[0]">
          <el-carousel-item v-for="item in AdDataValue" :key="item.id" style="height: 300px !important">
            <a :href="item.link.includes('http')?item.link:'https://'+item.link" target="_blank" rel="noopener noreferrer">
              <img style="width: 100%;height: 100%;" :src="item.attachList[0].link" />
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="modular_wrap_business">
        <div class="modular_wrap_center_business">
          <div class="line_l"></div>
          <div class="block"></div>
          <div class="text_business">主营业务</div>
          <div class="block"></div>
          <div class="line_r"></div>
        </div>
        <div class="modular_wrap_center_content">
          <div class="modular_wrap_business_top" v-for="item in mainBusiness" :key="item.id" @click.stop="setHtml(item)">
            <div style="width:80px;height:80px;"><img :src="item && item.thumbnailUrlList && item.thumbnailUrlList[0] ? item.thumbnailUrlList[0].link : ''" alt="" style="width: 100%;height:100%;">
            </div>
            <div class="wrap_business_text">{{ item.description }}</div>
          </div>
        </div>
        <div class="modular_wrap_center_business">
          <div class="line_l"></div>
          <div class="block"></div>
          <div class="text_business">经典业绩</div>
          <div class="block"></div>
          <div class="line_r"></div>
        </div>
        <div class="modular_wrap_center_content" style="justify-content: space-between;">
          <div class="modular_box" v-for="i in classicPerformance" :key="i.id" @click.stop="setHtml(i)">
            <div class="name">{{i.title}}</div>
            <div class="box">
              <div style="height:10rem">
                <img :src="i && i.thumbnailUrlList && i.thumbnailUrlList[0] ? i.thumbnailUrlList[0].link : ''" alt="" style="width: 100%;height:100%;" class="image">
              </div>
              <div>
                <div class="box_title" style="text-align: left;">{{i.description}}</div>
                <div class="bottom">
                  <div class="time" v-html="$options.filters.ellipsis(i.content)"></div>
                  <el-button type="warning" size="small" class="button" @click.stop="moreHtml(i)">更多 ></el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgData from "@/data";
import { mainStore } from "@/store";
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { getAllAd, getBizDictByCode } from "@/api/index.js"
export default {
  filters: {
    ellipsis(value) {
      value = value.replace(/<\/?.+?>/g, "");
      value = value.replace(/ /g, " ");
      value = value.replace(/.*?\}/g, " ");
      value = value.replace(/.*?\}/g, "");
      return value;
    },
  },
  props: {
    ActiveList: {
      type: String,
      require: true,
    },
    ListData: {
      type: Array,
      require: true,
    },
    LiImgData: {
      type: Array,
      require: true,
    },
    subTitle: {
      type: String,
      require: true,
    },
    title: {
      type: String,
      require: true,
    },
    CurrentPage: {
      type: Number,
    },
    type: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const currentDate = ref(new Date())
    const showRightImg = ref(true);
    const storeOfmain = mainStore();
    const router = useRouter();
    const changeActiveList = (code) => {
      emit("changeActiveList", code);
    };
    const mainBusiness = storeToRefs(storeOfmain).mainBusiness
    const classicPerformance = storeToRefs(storeOfmain).classicPerformance
    const setHtml = (item) => {
      router.push({ path: "/InfoView", query: { id: item.id } })
    };
    const moreHtml = () => {
      storeOfmain.setactivePageUrl("/BusinessPerformance");
      router.push({ path: `/BusinessPerformance` })
    }
    const toTable = () => {
      if (props.type == "工程类") {
        storeOfmain.setactivePageUrl("/Bidding");
        router.push({
          path: "/Bidding",
        });
      } else {
        storeOfmain.setactivePageUrl("/Purchasing");
        router.push({
          path: "/Purchasing",
        });
      }
    };
    const Jump = (url) => {
      if (url.includes('http')) {
        window.open(url)
      } else {
        window.open('https://' + url)
      }
    }
    const changeCurrentPage = (type) => {
      if (
        (props.CurrentPage == 0 && type == "pre") ||
        (!showRightImg.value && type == "next")
      ) {
        return;
      }
      emit("changeCurrentPage", type);
    };
    const mockData = [
      {
        url: mainStore().CheckEngineerImg(0),
        title: mainStore().CheckEngineerTitle(0),
        subTitle: "叠石桥智慧云产储一体化项目复垦方案编制项目",
        isEnd: false,
        content:
          "根据招投标相关法律法规的规定南通市海门海泰工程项目管理有限公司受南通市海门鹏天建工有限公司",
      },
      {
        url: mainStore().CheckEngineerImg(1),
        title: mainStore().CheckEngineerTitle(1),
        subTitle: "海门港新区C21014商业项目盘扣式脚手架专业分包工程",
        isEnd: false,
        content:
          "本招标项目海门港新区C21014商业项目（1#酒店、商业B、商业C、商业D及相应地库）盘扣式脚手",
      },
      {
        url: mainStore().CheckEngineerImg(2),
        title: mainStore().CheckEngineerTitle(2),
        subTitle: "海门区全域内河码头近期落地实施方案编制项目 招标公告",
        isEnd: false,
        content:
          "根据政府采购相关法律法规的规定，南通东洲工程项目管理有限公司受 南通市海汇交通建设工程有限公",
      },
      {
        url: mainStore().CheckEngineerImg(3),
        title: mainStore().CheckEngineerTitle(3),
        subTitle: "海门港新区C21014商业项目智慧工地采购项目",
        isEnd: false,
        content:
          "根据招投标相关法律法规的规定南通市海门海泰工程项目管理有限公司受南通市海门鹏天建工有限公司",
      },
    ];
    const AdDataValue = ref([])
    const AdData = async () => {
      const res = await getAllAd()
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].adType == 4 || res.data[i].adType == null) {
          AdDataValue.value.push(res.data[i])
        }
      }
    }
    const modular_img = ref([
      { pic: require('./pic/pic1.png') },
      { pic: require('./pic/pic2.png') },
      { pic: require('./pic/pic3.png') },
      { pic: require('./pic/pic4.png') },
      { pic: require('./pic/pic5.png') },
    ])
    const pamarsUrl = ref([])
    const getUrl = async () => {
      const res = await getBizDictByCode('url_page')
      pamarsUrl.value = res.data.map((item, index) => {
        const newItem = { ...item }
        if (modular_img.value[index]) {
          newItem.pic = modular_img.value[index].pic
          newItem.link = modular_img.value[index].link
        }
        return newItem
      })
      console.log(pamarsUrl, 'pamarsUrl');
    }

    onMounted(async () => {
      await AdData()
      await getUrl()
    })
    return {
      AdDataValue,
      pamarsUrl,
      getUrl,
      AdData,
      Jump,
      moreHtml,
      ...imgData,
      changeActiveList,
      setHtml,
      changeCurrentPage,
      toTable,
      showRightImg,
      mockData,
      modular_img,
      currentDate,
      mainBusiness,
      classicPerformance
    };
  },
};
</script>

<style scoped>
@import url("./Modular.css");

.modular_box {
  width: 23.9%;
  padding: 10px 0px;
  cursor: pointer;
}

.box {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  position: relative;
  /* padding-bottom: 56px; */
  min-height: 322px;
}

.bottom {
  margin-top: 13px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
}

.name {
  font-size: 18px;
  font-weight: 700;
  padding-top: 20px;
  padding-bottom: 10px;
}

.box_title {
  width: 100%;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  padding: 20px 10px 0 10px;
}

.time {
  width: 93%;
  font-size: 12px;
  padding: 20px 10px 0 10px;
  color: #999;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
}

.button {
  width: 56px;
  height: 30px;
  margin: 10px 20px 10px 0;
  position: absolute;
  bottom: 0;
}
</style>

