<template>
  <div class="helpCenter_all_wrap">
    <div class="helpCenter_wrap">
      <div class="helpCenter_wrap_l">
        <img src="./pic/procurementLogo.png" alt="" style="width: 100%;height: 100%;">
      </div>
      <div class="helpCenter_wrap_r">
        <!-- <div class="wrap_more">更多 ></div> -->
        <el-tabs v-model="activeName" type="border-card" @tab-click="tabClick">
          <el-tab-pane :label="item.title" :name="index + 1 + ''" v-for="(item, index) in titleArr" :key="item.id">
            <div class="card_box" @click="setHtml(item.type,i.id,i.gsId)" v-for="i in Table.value" :key="i.id" style="padding: 20px;">
              <div class="card_box_text">{{ i.bulletinName }}</div>
              <div>{{ formatCreateTime(i.publishTime.slice(0, 11)) }}</div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="helpCenter_wrap">
      <div class="helpCenter_wrap_l">
        <img src="./pic/auctionLogo.png" alt="" style="width: 100%;height: 100%;">
      </div>
      <div class="helpCenter_wrap_r">
        <!-- <div class="wrap_more">更多 ></div> -->
        <el-tabs v-model="activeName" type="border-card">
          <el-tab-pane :label="item.title" :name="index + 1 + ''" v-for="item in PmArr" :key="item.type">
            <div class="card_box" @click="jumpDetail(i.id)" v-for="i in PmTable.value" :key="i.id" style="padding: 20px;">
              <div class="card_box_text">{{ i.filterContent }}</div>
              <div>{{ formatCreateTime(i.updateTime.slice(0, 11)) }}</div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import imgData from "@/data";
import { reactive, computed, ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import _ from "lodash";
import { useRouter } from "vue-router";
import { listFindTenderBulletin, getCmsList } from "@/api/index";
// import { scrollToLocation } from "@/util/index";

export default {
  setup() {
    const titleArr = [
      { title: '招标公告', type: '0' },
      { title: '中标公告', type: '3' },
      { title: '变更公告', type: '2' },
    ]
    const PmArr = [
      { title: '拍卖公告', type: '0' },
    ]
    const router = useRouter();

    const index = ref(0)
    const Table = reactive([])
    const fetchData = async (type) => {
      const res = await listFindTenderBulletin({ bulletinType: type })
      return res.data.slice(0, 5)
    }
    const tabClick = async (tab, event) => {
      index.value = tab.index
      if (index.value == 0) {
        const res = await fetchData(0)
        Table.value = res
      } else if (index.value == 1) {
        const res = await fetchData(3)
        Table.value = res
      } else {
        const res = await fetchData(2)
        Table.value = res
      }
    }
    const PmTable = reactive([])
    const PmData = async () => {
      const res = await getCmsList()
      PmTable.value = res.data.records.slice(0, 5)
    }

    const jumpDetail = (id) => {
      router.push({
        path: "/AuctionDetails",
        query: { id: id },
      })
    };

    onMounted(async () => {
      Table.value = await fetchData(0)// 默认查询类型为0的数据
      await PmData()
    })
    const getCategoryAllData = (
      categoryName,
      length,
      chunk = false,
      chunkLength = 2
    ) => {
      return {
        CategoryData: computed(() => {
          return storeToRefs(store).AllNavData.value.find(
            (v) => v.categoryName == categoryName
          );
        }),
        ArticleData: chunk
          ? computed(() => {
            return storeToRefs(store).Article.value[categoryName]
              ? _.chunk(
                storeToRefs(store).Article.value[categoryName].slice(
                  0,
                  length
                ),
                chunkLength
              )
              : storeToRefs(store).Article.value[categoryName];
          })
          : computed(() => {
            return storeToRefs(store).Article.value[categoryName]
              ? storeToRefs(store).Article.value[categoryName].slice(
                0,
                length
              )
              : storeToRefs(store).Article.value[categoryName];
          }),
      };
    };
    const GuideData = getCategoryAllData("使用指南", 15, true, 5);
    const manipulationVideoData = getCategoryAllData(
      "视频操作教学",
      12,
      true,
      6
    );
    const ToolsDownData = getCategoryAllData("工具下载", 8, true);
    ToolsDownData.ArticleData.value = ToolsDownData;
    const toHref = (url) => {
      // location.href = url;
      window.open(window.location.origin + "/" + url, "_blank");
    };
    const getUrl = (name) => {
      return window.location.origin + "/" + name;
    };
    const setHtml = (type, id, gsId) => {
      router.push({
        path: "/BiddingAuction",
        query: { id: id, type: type, gsId: gsId },
      })
    };
    const handleFullScreen = (e) => {
      launchFullscreen(e.target);
    };
    const launchFullscreen = (element) => {
      //此方法不可以在異步任務中執行，否則火狐無法全屏
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen();
      } else {
        var docHtml = document.documentElement;
        var docBody = document.body;
        var videobox = element;
        var cssText = "width:100%;height:100%;overflow:hidden;";
        docHtml.style.cssText = cssText;
        docBody.style.cssText = cssText;
        videobox.style.cssText = cssText + ";" + "margin:0px;padding:0px;";
        document.IsFullScreen = true;
      }
    };
    const changeShow = (type) => {
      // ToolsDown、Guide、manipulationVideo
      switch (type) {
        case "ToolsDown":
          showToolsDown.value = !showToolsDown.value;
          showGuide.value = false;
          showmanipulationVideo.value = false;
          break;
        case "Guide":
          showToolsDown.value = false;
          showGuide.value = !showGuide.value;
          showmanipulationVideo.value = false;
          break;
        case "manipulationVideo":
          showToolsDown.value = false;
          showGuide.value = false;
          showmanipulationVideo.value = !showmanipulationVideo.value;
          break;
      }
    };
    const handleExitFullScreen = (e) => {
      exitFullscreen(e.target);
    };
    const exitFullscreen = (element) => {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.oRequestFullscreen) {
        document.oCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else {
        var docHtml = document.documentElement;
        var docBody = document.body;
        var videobox = element;
        docHtml.style.cssText = "";
        docBody.style.cssText = "";
        videobox.style.cssText = "";
        document.IsFullScreen = false;
      }
    };
    const showToolsDown = ref(false);
    const showGuide = ref(false);
    const showmanipulationVideo = ref(false);
    const data = reactive({
      manipulationVideoData,
      ToolsDownData,
      GuideData,
      titleArr,
      Table,
      index,
      tabClick
    });
    return {
      ...data,
      ...imgData,
      toHref,
      setHtml,
      scrollTop: 0,
      getUrl,
      handleFullScreen,
      handleExitFullScreen,
      changeShow,
      showToolsDown,
      showGuide,
      showmanipulationVideo,
      activeName: '1',
      PmArr,
      PmData,
      PmTable,
      jumpDetail
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop); //监听滚动条
    // console.log(1, this.$refs.helpCenterAllWrap)
  },
  methods: {
    formatCreateTime(timeString) {
      const date = new Date(timeString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}年${month}月${day}日`;
    },
    handleToHelpCenter() {
      let timer = setInterval(() => {
        // let ispeed = Math.floor(
        //   this.$refs.helpCenterAllWrap.getBoundingClientRect().top / 1
        // );
        let ispeed = this.$refs.helpCenterAllWrap.getBoundingClientRect().top;
        console.log("ispeed", ispeed);
        document.documentElement.scrollTop = document.body.scrollTop =
          this.scrollTop + ispeed;
        console.log(
          "scrollTop",
          this.scrollTop,
          this.$refs.helpCenterAllWrap.getBoundingClientRect().top
        );
        // if (this.$refs.helpCenterAllWrap.getBoundingClientRect().top === 0) {
        clearInterval(timer);
        // }
      }, 30);
    },
    // 监听到的滚动的距离
    scrollToTop() {
      this.scrollTop =
        window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log('this.scrollTop',this.scrollTop);

      // 计算距离顶部的高度，当高度大于300显示回顶部图标，小于300则隐藏
      // if (this.scrollTop > 300) {
      //   this.topShow = true;
      // } else {
      //   this.topShow = false;
      // }
    },
  },
};
</script>

<style scoped>
@import url("./HelpCenter.css");
</style>
  <style scoped >
::v-deep .el-tabs {
  --el-tabs-header-height: 50px;
}
::v-deep .is-active::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

::v-deep .el-table__row {
  font-size: 16px;
}

::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

::v-deep .el-cascader-panel {
  z-index: 999;
  border-radius: 4px;
}

::v-deep .el-cascader-menu {
  border-right: none;
  background: #fff;
  z-index: 999;
}

::v-deep .el-cascader-panel.is-bordered {
  border: none;
}

::v-deep .el-carousel__button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

::v-deep.el-menu--horizontal > .el-menu-item.is-active {
  color: #fff !important;
  border-bottom-color: #fff !important;
}

::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: #014f80 !important;
  color: #fff !important;
  border-bottom-color: #fff !important;
}

::v-deep.el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-submenu.is-active > .el-submenu__title:hover {
  background-color: #014f80 !important;
  color: #fff !important;
}

::v-deep.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background-color: #014f80 !important;
}

::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  background: linear-gradient(
      180deg,
      rgba(0, 102, 166, 1) 0%,
      rgba(59, 133, 243, 1) 100%
    ),
    rgba(0, 102, 166, 1);
  color: #fff;
  border: none;
  border-radius: 4px;
}

::v-deep.el-tabs--border-card {
  height: 100%;
}

::v-deep.el-tabs--border-card > .el-tabs__content {
  padding: 0;
}

::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  outline: 0;
  color: #303133;
  color: #fff !important;
  background-color: #014f80 !important;
}

::v-deep .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 2px solid #409eff;
  color: #303133;
  border-bottom-color: #fff !important;
  background-color: #014f80 !important;
  color: #fff !important;
}

::v-deep .el-table .el-table__cell {
  color: #303133;
}
</style>
