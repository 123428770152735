
<template>
  <BasePage>
    <Banner />
    <DlogAd />
    <!-- <Service /> -->
    <NewsCenter />
    <!-- 顶部轮播图 -->
    <!-- <Purchasing /> -->
    <!-- 中心轮播图 -->
    <!-- <AdBanner /> -->
    <Engineering />
    <!-- 底部伦比图 -->
    <!-- <AdBanner2 /> -->
    <HelpCenter />
  </BasePage>
</template>


<script>
import DlogAd from "./components/DlogAd/DlogAd";
import BasePage from "@/components/BasePage/BasePage";
import Banner from "@/components/BasePage/components/Banner/Banner.vue";
import Engineering from "./components/Engineering/Engineering";
import HelpCenter from "./components/HelpCenter/HelpCenter";
import NewsCenter from "./components/NewsCenter/NewsCenter";
import { reactive, computed } from "vue";
import { mainStore } from "@/store/index";
import { storeToRefs } from "pinia";

export default {
  components: {
    BasePage,
    Engineering,
    HelpCenter,
    NewsCenter,
    Banner,
    DlogAd
  },
  setup() {
    const store = mainStore();
    const AdData = computed(() => {
      return storeToRefs(store).AdData;
    });
    const data = reactive({
      AdData,
    });

    return {
      ...data,
    };
  },
  methods: {
    toHelpCenter() {
      this.$refs.HelpCenter.handleToHelpCenter();
    },
  },
};
</script>

<style scoped>
</style>