<template>
  <BaseModular subTitle="新闻中心" title="">
    <div class="index_news_center_wrap">
      <div class="index_news_center_left" style="object-fit: cover;">
        <el-carousel :interval="5000" style="height: 100%; width: 100%" indicator-position="none" v-if="AdDataValue[0]">
          <el-carousel-item v-for="item in AdDataValue" :key="item.id" style="height: 400px !important">
            <img style="height: 400px" :src="item.attachList[0].link" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="index_news_center_right">
        <el-tabs v-model="activeName" type="border-card" @tab-click="tabClick" id="card">
          <el-tab-pane :label="item0.title" :name="index + 1 + ''" v-for="(item0, index) in arrTitle" :key="item0.id">
            <template v-if="index === 0" v-for="(item,t) in NewsData" :key="item.id">
              <div class="card_box" @click="setHtml(item)" v-if="t==0">
                <div style="width: 10.625rem;height:6.875rem;"><img :src="item.thumbnailUrlList[0].link" alt="" style="width: 100%;height:100%;"></div>
                <div style="max-height:6.875rem;display:flex;flex-direction: column;justify-content: space-between;width: 100%;padding-left: 10px;">
                  <div class="flex_text">{{ item.description }}</div>
                  <div style="align-self: flex-end;">{{ item.createTime.slice(0, 11) }}</div>
                </div>
              </div>
              <div class="card_box" @click="setHtml(item)" v-else>
                <div style="max-height:6.875rem;display:flex;width: 100%;padding-left: 10px;justify-content: space-between;">
                  <div class="flex_text">{{ item.description }}</div>
                  <div style="align-self: flex-end;">{{ item.createTime.slice(0, 11) }}</div>
                </div>
              </div>
            </template>
            <template v-if="index === 1" v-for="(item,t) in NewsDataC" :key="item.id">
              <div class="card_box" @click="setHtml(item)" v-if="t==0">
                <div style="width: 10.625rem;height:6.875rem;"><img :src="item.thumbnailUrlList[0].link" alt="" style="width: 100%;height:100%;"></div>
                <div style="max-height:6.875rem;display:flex;flex-direction: column;justify-content: space-between;width: 100%;padding-left: 10px;">
                  <div class="flex_text">{{ item.description }}</div>
                  <div style="align-self: flex-end;">{{ item.createTime.slice(0, 11) }}</div>
                </div>
              </div>
              <div class="card_box" @click="setHtml(item)" v-else>
                <div style="max-height:6.875rem;display:flex;width: 100%;padding-left: 10px;justify-content: space-between;">
                  <div class="flex_text">{{ item.description }}</div>
                  <div style="align-self: flex-end;">{{ item.createTime.slice(0, 11) }}</div>
                </div>
              </div>
            </template>
          </el-tab-pane>
          <div style="height: 52px;display: flex;align-items: center;justify-content: flex-end;margin-right: 20px;"><el-button type="primary" size="large" @click="MoreMessage">更多+</el-button></div>
        </el-tabs>
      </div>
    </div>
  </BaseModular>
</template>
  
<script>
import { reactive, computed, onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
import BaseModular from "@/components/BaseModular/BaseModular";
import { storeToRefs } from "pinia";
import { mainStore, stateStore } from "@/store";
import { getAllAd } from "@/api/index.js"
export default {
  components: { BaseModular },
  setup() {
    const store = mainStore();
    const router = useRouter();
    const AdDataValue = ref([])
    const AdData = async () => {
      const res = await getAllAd()
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].adType == 1 || res.data[i].adType == null) {
          AdDataValue.value.push(res.data[i])
        }
      }
    }
    const NewsData = computed(() => storeToRefs(store).newsAllData);
    const NewsDataC = computed(() => storeToRefs(store).PolicyRule);
    const setHtml = (item) => {
      stateStore().setactiveHtml(
        item.content,
        item.title,
        item.createTime,
        item
      );
      router.push({ path: "/InfoView", query: { id: item.id } })
    };
    const getUrl = (name) => {
      return window.location.origin + "/" + name;
    };
    const num = ref(0)
    const tabClick = (e) => {
      num.value = e.index
    };
    const MoreMessage = () => {
      if (num.value == 0) {
        router.push({ path: "/Listdetails", query: { Id: '1562273561959665665' } })
      } else {
        router.push({ path: "/Listdetails", query: { Id: '1562272838152818689' } })
      }

    };
    onBeforeMount(async () => {
      await AdData()
    })
    const data = reactive({
      NewsData,
      NewsDataC,
    });

    return {
      ...data,
      AdData,
      AdDataValue,
      getUrl,
      setHtml,
      tabClick,
      activeName: '1',
      arrTitle: [
        { title: '集团要闻' },
        { title: '政策法规' }
      ],
      MoreMessage
    };
  },
};
</script>
  
  <style scoped>
@import url("./NewsCenter.css");
</style>
  <style scoped>
::v-deep #card .is-active::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
::v-deep.el-tabs {
  --el-tabs-header-height: 50px;
}
::v-deep .el-tabs__nav {
  width: 100%;
}
::v-deep .el-table__row {
  font-size: 16px;
}
::v-deep .index_news_center_left .el-carousel__container {
  height: 400px !important;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  width: 50%;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

::v-deep .el-cascader-panel {
  z-index: 999;
  border-radius: 4px;
}

::v-deep .el-cascader-menu {
  border-right: none;
  background: #fff;
  z-index: 999;
}

::v-deep .el-cascader-panel.is-bordered {
  border: none;
}

::v-deep .el-carousel__button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

::v-deep.el-menu--horizontal > .el-menu-item.is-active {
  color: #fff !important;
  border-bottom-color: #fff !important;
}

::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: #014f80 !important;
  color: #fff !important;
  border-bottom-color: #fff !important;
}

::v-deep.el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-submenu.is-active > .el-submenu__title:hover {
  background-color: #014f80 !important;
  color: #fff !important;
}

::v-deep.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background-color: #014f80 !important;
}

::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  background: linear-gradient(
      180deg,
      rgba(0, 102, 166, 1) 0%,
      rgba(59, 133, 243, 1) 100%
    ),
    rgba(0, 102, 166, 1);
  color: #fff;
  border: none;
  border-radius: 4px;
}

::v-deep.el-tabs--border-card {
  height: 100%;
}

::v-deep.el-tabs--border-card > .el-tabs__content {
  padding: 0;
}

::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  outline: 0;
  color: #303133;
  color: #fff !important;
  background-color: #014f80 !important;
}

::v-deep .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 2px solid #409eff;
  color: #303133;
  border-bottom-color: #fff !important;
  background-color: #014f80 !important;
  color: #fff !important;
}

::v-deep .el-table .el-table__cell {
  color: #303133;
}
</style>

  